import React, { useState } from 'react'
import { Table, Button, Modal, Header, Segment, Label } from 'semantic-ui-react'
import moment from 'moment'

const Team = ({ team, teamNumber }) => {
    return (
        <Segment raised>
            <Label as="a" color="red" ribbon>
                Team {teamNumber}
            </Label>
            <Header>Score {team.score}</Header>

            {team.players.map((player, index) => {
                return (
                    <Segment key={index}>
                        <Label as="a" color="blue" ribbon>
                            Player {index}
                        </Label>
                        <p style={{marginTop: '12px'}}>
                            <b>Guid</b> {player.playerId}
                        </p>
                        <p>
                            <b>Type</b> {player.playerType}
                        </p>
                        <p>
                            <b>Result</b> {player.result}
                        </p>
                        <p>
                            <b>Status</b> {player.status}
                        </p>
                    </Segment>
                )
            })}
        </Segment>
    )
}
const TeamModal = ({ teams }) => {
    const [isOpen, setOpen] = useState(false)

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={isOpen}
            trigger={<Button>View</Button>}>
            <Modal.Header>Teams Info</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    {teams.map((team, index) => (
                        <Team key={index} team={team} teamNumber={index} />
                    ))}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="OK"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() => setOpen(false)}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}
const ScoreTable = ({ events, types }) => {
    if (events.length === 0) return <div>No score events found.</div>
    if (Object.keys(types).length === 0) return <div>No score events found.</div>
    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Match</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Started</Table.HeaderCell>
                    <Table.HeaderCell>Finished</Table.HeaderCell>
                    <Table.HeaderCell>Len</Table.HeaderCell>
                    <Table.HeaderCell>Level</Table.HeaderCell>
                    <Table.HeaderCell>Variant</Table.HeaderCell>
                    <Table.HeaderCell>Teams</Table.HeaderCell>
                    <Table.HeaderCell>Client</Table.HeaderCell>
                    <Table.HeaderCell>Platform</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Result</Table.HeaderCell>
                    <Table.HeaderCell>XP</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body
                style={{
                    fontSize: '12px',
                }}>
                {events.map((event, index) => {
                    return (
                        <Table.Row key={index}>
                            <Table.Cell
                                style={{
                                    lineHeight: '10px',
                                    fontSize: '10px',
                                }}>
                                {event.matchid}
                            </Table.Cell>
                            <Table.Cell singleLine>
                                {moment(event.timestamp).format('MMM Do YY h:mm A')}
                            </Table.Cell>
                            <Table.Cell singleLine>
                                {moment(event.startedAt).format('MMM Do YY h:mm A')}
                            </Table.Cell>
                            <Table.Cell singleLine>
                                {moment(event.finishedAt).format('MMM Do YY h:mm A')}
                            </Table.Cell>
                            <Table.Cell>{event.matchLength}</Table.Cell>
                            <Table.Cell>{event.level}</Table.Cell>
                            <Table.Cell>{event.gameVariant}</Table.Cell>
                            <Table.Cell>
                                <TeamModal teams={event.teams} />
                            </Table.Cell>
                            <Table.Cell>{event.clientVersion}</Table.Cell>
                            <Table.Cell>{event.platform}</Table.Cell>
                            <Table.Cell>{event.status}</Table.Cell>
                            <Table.Cell>{event.result}</Table.Cell>
                            {/* <Table.Cell>{event.teamScore}</Table.Cell> */}
                            <Table.Cell>{event.xpPointsWon}</Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
    )
}

export default ScoreTable
